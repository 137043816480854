.container {
    margin-top: 20px;
    border: 2px solid #BBB;
    padding: 20px;
    border-radius: 4px;
}
.totem_block {
    border-top: 4px solid #CCC;
    margin: 10px 0;
}
.totem_block:first-child {
    border-top: 4px solid transparent;
}