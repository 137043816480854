* {
    font-family: 'Roboto', sans-serif;
}
button {
    font-family: 'Roboto', sans-serif;
    color: #fff;
    background-color: cadetblue;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 15px 10px;
    cursor: pointer;
}